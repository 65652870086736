.weightHistoryCell {
  cursor: pointer;
  color: #0682fe;
  white-space: pre;
}

.noteTypeCell {
  display: flex;
  align-items: center;
  gap: 14px;
  width: 100%;
  justify-content: space-between;

  > img {
    transform: rotate(270deg);
  }

  p {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.inactive {
  color: var(--terciary-text);
}

.styledHeader {
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableContent {
  :global(.MuiDataGrid-columnHeader) {
    @media print {
      width: 130px !important;
      max-width: 130px !important;
      min-width: 130px !important;
    }
  }

  :global(.MuiDataGrid-cell) {
    @media print {
      width: 130px !important;
      max-width: 130px !important;
      min-width: 130px !important;
    }
  }

  :global(.MuiDataGrid-columnHeader:nth-child(2)) {
    @media print {
      display: none !important;
    }
  }

  :global(.MuiDataGrid-columnHeader:nth-child(5)) {
    @media print {
      display: none !important;
    }
  }

  :global(.MuiDataGrid-cell:nth-child(2)) {
    @media print {
      display: none !important;
    }
  }

  :global(.MuiDataGrid-cell:nth-child(5)) {
    @media print {
      display: none !important;
    }
  }

  :global(.MuiDataGrid-cell:last-child img) {
    @media print {
      display: none !important;
    }
  }
}
