@import "../../../assets/styles/_breakpoints.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex: 1;
  align-self: center;
  width: 100%;
  gap: 8px;
}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerText {
  width: auto;
  position: relative;
  h5 {
    color: var(--primary-text);
    text-align: left;
    font: normal normal bold 40px/1 Sora;
  }
  p {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--primary-text);
    text-align: left;
    font: normal normal normal 14px/1 Sora;
  }
}

.doughnutChart {
  flex: 1;
}

.doughnutChartContainer {
  width: 100%;
  position: relative;
}

.labels {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  column-gap: 5px;
  row-gap: 12px;
  margin-bottom: 16px;
}

.labelContainer {
  border-radius: 15px;
  padding: 4px 12px 6px 12px;
  display: flex;
  align-items: center;
  height: 28px;
}

.label {
  letter-spacing: -0.28px;
  color: white;
  white-space: nowrap;
  font: normal normal normal 12px/1 Sora;
  @include largeDesktop {
    font: normal normal normal 14px/1 Sora;
  }
}
