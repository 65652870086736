.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.wrapperItems {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  color: var(--text-primary);
  cursor: pointer;
}
