.inputButton,
.inputButton:hover {
  &:global(.MuiButtonBase-root) {
    display: flex;
    justify-content: space-between;
    height: 50px;
    min-width: 245px;
    background: var(--white);
    border: 1px solid var(--border-color);
    padding: 0px 12px 0px 12px !important;
    color: var(--primary-text);
    font-weight: normal;

    &.inputActive {
      border: 2px solid var(--border-color);
    }

    .placeholder {
      color: var(--secondary-text);
    }
  }
}
