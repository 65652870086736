:root {
  --gray-light: #afc4e219;
  --green-light: #e1ffc8;
  --gray-subtitle: #84878d;
  --gray-border: #ebecee;
  --gray-border-secondary: #cfd6e0;
  --gray-table-border: #eaecef;

  --white: #ffffff;
  --red: #ea5230;
  --red-dark: #d94120;
  --green: #46af1a;
  --green-light: #e4f4dd;
  --progress-bar-bg: #ecf0f8;
  --quaternary-button-focus: #e6ebf0;
  --orange: #fcac0b;
  --orange-dark: #fcac0f;
  --orange-light: #fcac0b26;
  --terciary-text: #88a6d1;
  --secondary-text: #5b7289;
  --primary-text: #25273a;
  --icon-bg-color: #dcebf9;
  --dark-green: #37a047;
  --green-transparent: #919e7f99;
  --border-color: #cfd6e0;
  --border-radius: 12px;

  --row-bg-error: #fdedea;
  --row-color-error: #c36c6c;

  --font-size-xl: 22px;
  --font-size-lg: 16px;
  --font-size-md: 14px;
  --font-size-sm: 13px;
  --font-size-xs: 12px;
}
