@import "../../../assets/styles/_breakpoints.scss";

.numberBanner {
  width: 100%;
  height: 120px;
  background: var(--gray-light);
  color: var(--green);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: black;
  gap: 10px;

  @include largeDesktop {
    height: 150px;
  }

  @media print {
    border: 1px solid var(--gray-table-border);
  }

  .value {
    letter-spacing: 0px;
    font: normal normal 700 30px/1 Sora;
    @include largeDesktop {
      font: normal normal bold 40px/1 Sora;
    }
  }

  .label {
    font: normal normal normal 16px/1 Sora;
  }
}

.neutral {
  opacity: 1;
  .value {
    color: var(--green);
  }
  .label {
    color: var(--terciary-text);
    opacity: 1;
  }
}

.recyclingUnsuccessful {
  background: var(--red);
  .value {
    color: white;
    @media print {
      color: var(--red);
    }
  }
  .label {
    color: white;
    opacity: 1;
    @media print {
      color: var(--terciary-text);
    }
  }
}

.recyclingModerate {
  background: var(--orange);
  .value {
    color: white;
    @media print {
      color: var(--orange);
    }
  }
  .label {
    color: white;
    opacity: 1;
    @media print {
      color: var(--terciary-text);
    }
  }
}

.recyclingSuccessful {
  background: var(--green);
  .value {
    color: white;
    @media print {
      color: var(--green);
    }
  }
  .label {
    color: white;
    opacity: 1;
    @media print {
      color: var(--terciary-text);
    }
  }
}
