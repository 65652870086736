.indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--red);
}

.active {
  background-color: var(--green) !important;
}

.tooltipContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  padding: 4px 8px;
}
