.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font: normal normal normal 14px/25px Sora;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.button {
  width: 200px;
}

.minHeight {
  min-height: 80px;
}
