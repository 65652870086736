@import "../../assets/styles/_breakpoints.scss";

.pageContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 50px 50px 50px;
  overflow: auto;

  @include largeDesktop {
    padding: 0 80px 80px 80px;
  }
  @include extraLargeDesktop {
    padding: 0 120px 120px 120px;
  }
}

.fullScreen {
  padding: 0px;

  .pageHeader,
  .banner {
    padding: 0 50px;
    @include largeDesktop {
      padding: 0 80px;
    }
    @include extraLargeDesktop {
      padding: 0 120px;
    }
  }

  .pageContent {
    max-height: 100%;
  }
}

.pageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100px;
  line-height: 1;
  @include extraLargeDesktop {
    min-height: 140px;
  }
  h2 {
    font: normal normal 600 24px/1 Sora;
    letter-spacing: 0px;
    color: var(--primary-text);
    // TODO
    white-space: nowrap;
  }
}

.pageContent {
  display: flex;
  max-height: 100%;
  > div {
    width: 100%;
  }
}

.fullHeight {
  height: 100%;
  flex: 1;
}
