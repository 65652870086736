.container {
  margin: 16px;
  cursor: default;
  min-width: 218px;

  > div {
    &:first-child {
      margin-top: 0px;
    }
    margin-top: 20px;
  }

  .header {
    h1 {
      cursor: pointer;
      font-size: 16px;
      color: var(--primary-text);
      white-space: nowrap;
      display: flex;
      justify-content: space-between;
    }
    span {
      font-size: 14px;
      color: var(--terciary-text);
    }
  }

  .tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 14px;
    text-align: center;
    div {
      font-size: 12px;
      cursor: pointer;
      div {
        width: 44px;
        height: 44px;
        border-radius: 22px;
        background: var(--progress-bar-bg);
        display: flex;
        justify-content: center;
      }

      &.active {
        div {
          background: var(--terciary-text);
        }
      }
    }
  }

  .row {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    &:not(.rowIndented) span:first-child {
      display: flex;
      gap: 6px;
      color: var(--primary-text);
    }

    &.rowIndented {
      margin-left: 30px;

      span {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &:not(:first-child) {
        margin-top: 10px;
      }
      &:not(:last-child) {
        border-bottom: 1px solid var(--gray-border);
        padding-bottom: 10px;
      }
    }
  }
}
