.openComponent {
  cursor: pointer;
}

.content {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 30rem;
}

.title {
  font-size: large;
}

.actions {
  display: flex;
  gap: 1rem;
  font-weight: bold;
}

.text {
  line-height: 1.75rem;
}
