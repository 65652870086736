@import "../../assets/styles/breakpoints";

.container {
  font-size: 25px;
  color: var(--gray-subtitle);
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 15px;
  span {
    font-size: 14px;
  }
  @media print {
    img {
      display: none;
    }
  }
}

.backButtonContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 300px;
  @include largeDesktop {
    max-width: unset;
  }
  @include extraLargeDesktop {
    max-width: unset;
  }
  h4 {
    color: var(--primary-text);
    text-align: left;
    font: normal normal 600 24px/1 Sora;
  }
  h5 {
    color: var(--secondary-text);
    text-align: left;
    font: normal normal normal 14px/25px Sora;
    letter-spacing: 0px;
  }
}
