.formControl {
  height: 50px;
  > div {
    height: 100%;
  }

  :global(.MuiSelect-root) {
    padding: 0 0 0 15px !important;
    height: calc(100% - 2px);
    display: flex;
    align-items: center;
    font-family: Sora;
  }

  :global(.MuiSelect-select) {
    font-size: 14px !important;
    height: 100% !important;
    box-sizing: border-box !important;
    display: flex !important;
    align-items: center !important;

    @media print {
      border: none !important;
    }
  }
}
.fixedWidth {
  width: 98px !important;
}

.minWidth {
  min-width: 200px !important;
}

.normalWidth {
  min-width: 300px !important;
}

.yearSelect {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 16px;
  p {
    color: var(--primary-text);
    text-align: right;
    font: normal normal normal 14px/1 Sora;
  }
}

.select {
  box-sizing: border-box;

  :global(.MuiInputBase-input) {
    border-radius: 4px;
    position: relative;
    background-color: var(--white);
    font-size: 16px;
    padding: 10px 26px 10px 12px;
    line-height: 1;
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    color: var(--primary-text);
    font: normal normal normal 14px/25px Sora;
    border: 1px solid var(--border-color);

    &:focus {
      border: 1px solid var(--border-color);
    }

    &:global(.Mui-disabled) {
      color: var(--secondary-text);
      -webkit-text-fill-color: var(--secondary-text);
    }

    &[aria-expanded="true"] {
      border: 2px solid var(--border-color);
    }
  }

  :global(.MuiOutlinedInput-notchedOutline) {
    border: none;
  }

  &:global(.Mui-focused) {
    fieldset {
      &:global(.MuiOutlinedInput-notchedOutline) {
        border: none;
      }
    }
  }
}

.icon {
  position: absolute;
  right: 8px;
  display: inline-block;
  width: 24px;
  height: 24px;
  pointer-events: none;

  @media print {
    display: none;
  }
}

.flexContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
