.controls {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 10px;
}

.yearSelect {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 16px;
}
