@import "../../../assets/styles/breakpoints";

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.controls {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 10px;
}

.inviteToMobileAppBtn {
  height: 50px;

  @media print {
    display: none !important;
  }
}

.center {
  display: flex;
  align-items: center;
  gap: 12px;
  > p {
    color: var(--primary-text);
    font: normal normal normal 14px/1 Sora;
  }
}

.buttonsContainer {
  display: flex;
  gap: 6px;
}

.nameWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
