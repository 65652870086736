.map {
  :global(.mapboxgl-marker) {
    &:hover,
    &:focus,
    &[open="true"] {
      z-index: 1500;
    }
  }

  :global(.mapboxgl-ctrl.mapboxgl-ctrl-group) {
    box-shadow: none;
    display: flex;
    flex-direction: column;
    background: none;
    gap: 16px;

    button {
      background: var(--white);
      border-radius: 4px;
      width: 36px;
      height: 36px;
      border: none;
      box-shadow: 2px 4px 12px #00000018;
    }

    :global(button.mapboxgl-ctrl-zoom-in) {
      :global(.mapboxgl-ctrl-icon) {
        background-image: url("./zoomIn.svg");
      }
    }
    :global(button.mapboxgl-ctrl-zoom-out) {
      :global(.mapboxgl-ctrl-icon) {
        background-image: url("./zoomOut.svg");
      }
    }
  }
}
