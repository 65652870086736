.container {
  cursor: default;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  color: white;
  font-weight: bold;
  opacity: 0.5;
}

:global(.MuiTooltip-tooltip) {
  background-color: white !important;
  box-shadow: #00000008 2px 14px 18px;
  color: var(--secondary-text) !important;
  font-family: "Sora" !important;
  font-size: 13px !important;
  max-width: none !important;
}
:global(.MuiTooltip-arrow) {
  color: white !important;
}
.open {
  z-index: 500;
}
