.content {
  margin-top: 49px;
  font-weight: normal;
}

.footer {
  font-size: 14px;
  a {
    color: var(--terciary-text);
    text-decoration: underline;
  }
}

.loginBtn {
  height: 50px;
}

.userAuthLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-size: cover;
}
