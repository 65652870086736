.container {
  padding: 24px;
  background-color: var(--white);
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  margin-bottom: 32px;
  justify-content: space-between;

  .content {
    color: var(--green);
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    h1 {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
    }
    span {
      padding-left: 32px;
    }
  }
}
