@import "../../../assets/styles/breakpoints";

.container {
  display: flex;
  gap: 22px;
  padding: 24px 27px 18px 24px;

  @include largeDesktop {
    height: 140px;
  }
}

.chartContainer {
  width: 52px;
  height: 52px;
  position: relative;
  z-index: 1;
  top: 5px;
}

.text {
  color: var(--white);
  font: normal normal normal 14px/24px Sora;
  letter-spacing: 0px;
}
.degreeOfSeparation {
  white-space: nowrap;
}

.recyclingUnsuccessful {
  background: var(--red);
}

.recyclingModerate {
  background: var(--orange);
}

.recyclingSuccessful {
  background: var(--green);
}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerText {
  width: auto;
  position: relative;
  h5 {
    font: normal normal 800 14px/25px Sora;
    letter-spacing: 0px;
    color: var(--white);
  }
}
