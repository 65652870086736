@import "../../assets/styles/breakpoints";

.contentWrapper {
  display: flex;
  flex-direction: column;
}

.content {
  gap: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 8;
  padding: 40px 70px 24px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 12px;
  h2 {
    font-size: 22px;
    margin: 0;
  }

  @media print {
    padding: 0;
  }
}
