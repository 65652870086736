.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form {
  display: flex;
  flex-direction: column;
  font: normal normal normal 14px/25px Sora;
}

.buttonContainer {
  margin-top: 30px;
  text-align: right;
  width: 100%;
}

.button {
  width: 200px;
}
