.alignIcon {
  display: flex;
  overflow: hidden;
  text-align: center;
  white-space: break-spaces;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  width: 0.8rem;
  margin-left: 0.8rem;
}
