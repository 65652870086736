.container {
  height: 116px;
  width: 100%;
  display: flex;
  border-radius: 12px;
  padding: 24px;
  gap: 20px;
  position: relative;

  .iconContainer {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    span {
      padding-top: 12px;
    }
  }

  .tooltip {
    position: absolute;
    right: 18px;
    top: 18px;
  }
}
