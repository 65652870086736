.snackbar {
  height: 40px;
  max-height: 40px;
  border-radius: 8px;
  :global(.MuiAlert-message) {
    font: normal normal normal 14px/1 Sora;
    letter-spacing: 0px;
    color: #ffffff;
  }
}
