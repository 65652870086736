.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.content {
  gap: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 8;
  padding: 50px 40px 24px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 12px;
  h2 {
    font-size: 22px;
    margin: 0;
  }
}

.yearSelect {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 16px;
}

.controlsWrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.editButton {
  @media print {
    display: none;
  }
}
