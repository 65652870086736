.wrapper {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 80px;
  font-size: 14px;
}

.wrapperNavigation {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.navigationItem {
  padding: 12px 16px;
  color: var(--primary-text);
  border-radius: 8px;
}

.active {
  background-color: var(--gray-light);
  font-weight: 500;
}
