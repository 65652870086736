.pageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 35px;
  width: 100%;
}

.secondaryColor {
  color: var(--secondary-text);
  display: inline-block;
  line-height: 1;

  .bold {
    font-weight: bold;
  }
}

.inputs {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filtersContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.filters {
  display: flex;
  align-items: center;
  gap: 6px;
}

.capitalize {
  text-transform: capitalize;
}
