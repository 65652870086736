.scroll {
  max-height: 58vh;

  overflow-y: scroll !important;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background: #c3c3c3;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0;
  }
}

.container {
  height: 100%;

  :global(.MuiDataGrid-root) {
    border: none !important;
    font-family: Sora !important;
    .styledHeader {
      color: var(--terciary-text);
      font: normal normal normal 14px/1 Sora;
      letter-spacing: -0.28px;
    }

    :global(.MuiDataGrid-row) {
      max-height: none !important;
      &:hover {
        cursor: pointer;
        background: white !important;
      }
    }

    :global(.MuiDataGrid-cell) {
      line-height: unset !important;
      white-space: normal;
      word-wrap: break-word;
      &:focus-within {
        outline: none !important;
      }
      &:nth-of-type(14) {
        display: none;
      }
    }

    :global(.MuiDataGrid-columnSeparator--sideRight) {
      display: none !important;
    }

    :global(.MuiDataGrid-renderingZone) {
      max-height: none !important;
    }

    :global(.MuiDataGrid-columnHeaderTitleContainer) {
      align-items: center;
      justify-content: center;
      height: 50px;
    }
    :global(.MuiDataGrid-columnHeader) {
      cursor: pointer;
      &:focus {
        outline: none !important;
      }
    }

    .rowHeader {
      white-space: pre-wrap !important;
      flex-wrap: wrap;
      font: normal normal 500 14px/20px Sora;
    }
    .rowCell {
      border-right: 1px solid var(--gray-table-border);
      padding: 8px;
      min-height: 54px !important;
      max-height: unset !important;
      &:first-of-type {
        border-left: 1px solid var(--gray-table-border);
      }
    }

    :global(.MuiDataGrid-virtualScrollerContent) {
      border-radius: 4px;
    }

    :global(.Mui-selected) {
      background-color: white !important;
    }
  }
}
