.container {
  height: 100%;
  width: 100%;
}

.input {
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  text-align: center;
  outline: none;
  font: normal normal normal 14px/1 Sora;
}

.total {
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-family: "Sora";
}
