@import "../../assets/styles/_breakpoints.scss";
.content {
  gap: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 8;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 100%;
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: 12px;
  padding: 40px 40px 24px;

  @include largeDesktop {
    padding: 50px 40px 24px 40px;
  }

  h2 {
    font-size: 22px;
    margin: 0;
  }

  @media print {
    padding: 0;
  }
}
