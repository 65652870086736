.container {
  border-radius: 4px;
  height: 50px;
  padding: 7px 8px 7px 20px;
  display: flex;
  align-items: center;
  gap: 24px;

  @media print {
    display: none;
  }
}

.orange {
  background-color: var(--orange-light);
  .text {
    color: var(--orange);
  }
}

.green {
  background-color: var(--green-light);
  .text {
    color: var(--green);
  }
}

.text {
  font: normal normal 500 14px/1 Sora;
  letter-spacing: 0px;
}

.clipContainer {
  padding: 8px;
  background: var(--orange-dark);
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
