@import "../../assets/styles/_breakpoints.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 40px;
}

.wrapperItems {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  margin-right: 30px;

  @include largeDesktop {
    margin-right: 40px;
  }

  .saveBtn {
    min-width: 200px;
  }
}

.wrapperToggle {
  display: flex;
  align-items: center;
  gap: 24px;
}

.wrapperWasteType {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.wasteTypeId {
  font-size: 12px;
  color: var(--secondary-text);
}

.unit {
  padding-right: 8px;
}

.generateCredentialsContainer {
  display: flex;
  flex-direction: column;
  align-items: start;

  .title {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 8px;
  }

  .button {
    margin-top: 20px;
    height: 40px;
  }

  .buttonText {
    font-size: 14px;
  }
}

.credentialsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
}

.credentialContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.iconContainer {
  height: 36px;
  width: 36px;
  padding: 8px;
  background: var(--gray-border);
  border-radius: 4px;
  cursor: pointer;
}

.label {
  padding-bottom: 4px;
}

.credential {
  font-weight: 600;
}
