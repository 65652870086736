.date {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.holiday {
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 10px;
  background-color: var(--red);
  color: var(--white);
}
