.loaderContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconContainer {
  width: 248px;
  height: 248px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--white);
  z-index: 100;
}

.fullPage {
  height: 100vh;
}
