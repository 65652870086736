.flexWrapper {
    display: flex;
    gap: 10px;
}

.circle {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    align-self: center;
}