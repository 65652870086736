@import "../../../assets/styles/_breakpoints.scss";
.graphsContainer {
  display: flex;
  flex: 1;
  gap: 20px;

  @include extraLargeDesktop {
    gap: 30px;
  }
}

.rightSegment {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @include desktop {
    min-width: 540px;
  }
  @include largeDesktop {
    max-width: 1080px;
  }
  @include extraLargeDesktop {
    min-width: 720px;
    gap: 30px;
    max-width: unset;
  }
}

.leftCard {
  width: 360px;
  min-width: 360px;
  max-width: 360px;
  align-self: flex-start;

  @include largeDesktop {
    width: 480px;
    min-width: 480px;
    max-width: 480px;
  }
  @include extraLargeDesktop {
    min-width: 520px;
    max-width: 520px;
    width: 520px;
  }
}

// .leftCard,
// .rightCard {
//   > div:last-of-type {
//     padding: 0px 40px 24px 40px;
//   }
// }
