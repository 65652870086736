.container {
  height: 100%;

  :global(.MuiDataGrid-root) {
    border: none;
    font-family: Sora;

    :global(.MuiDataGrid-columnHeader) {
      color: var(--terciary-text);
      cursor: pointer;

      &:focus,
      &:focus-within {
        outline: none !important;
      }
    }

    :global(.MuiDataGrid-row:hover) {
      cursor: pointer;
    }
    :global(.MuiDataGrid-columnSeparator--sideRight) {
      display: none;
    }

    :global(.MuiDataGrid-columnHeaders) {
      line-height: inherit;
    }

    :global(.MuiDataGrid-columnHeaderTitleContainer) {
      padding: 0;
    }

    :global(.Mui-selected) {
      background-color: white;
    }

    :global(.MuiDataGrid-cell) {
      line-height: unset !important;
      max-height: none !important;
      min-height: 56px !important;
      height: 56px !important;
      white-space: normal;
      border: none;
      &:focus-within {
        outline: none !important;
      }
    }
    :global(.MuiDataGrid-row) {
      max-height: none !important;
      min-height: 56px !important;
      height: 56px !important;
      margin-bottom: 0;
      border-radius: 8px;
      &:first-of-type {
        margin-top: 10px;
      }
      &:hover,
      &:global(.error):hover {
        background: var(--gray-light) !important;
        cursor: pointer !important;
      }
      > :global(.MuiDataGrid-cell):last-of-type {
        padding: 0 30px 0 10px;
      }

      &:global(.error) {
        background-color: var(--row-bg-error) !important;
        color: var(--row-color-error) !important;
      }
    }

    :global(.MuiDataGrid-columnHeadersInner) {
      > div {
        > :global(.MuiDataGrid-columnHeader):last-of-type {
          padding: 0 30px 0 10px;
        }
      }
    }

    :global(.MuiCircularProgress-root) {
      color: var(--green);
    }
    :global(.MuiDataGrid-footerContainer) {
      padding-top: 0.5rem !important;
    }
    :global(.MuiTablePagination-displayedRows) {
      display: none;
    }
    :global(.MuiDataGrid-iconButtonContainer) {
      display: none;
    }

    :global(.MuiDataGrid-cell:focus-within) {
      outline: none !important;
    }

    :global(.MuiDataGrid-overlay) {
      z-index: 100;
      width: 2.5rem !important;
      height: 2.5rem !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate3d(-50%, -50%, 0) !important;
    }
  }
}

.isLoading {
  :global(.MuiDataGrid-root) {
    :global(.MuiDataGrid-row) {
      &:hover {
        background: inherit !important;
      }
    }
  }
}
