.tableContent {
  $columnWidth: 315px;

  :global(.MuiDataGrid-columnHeader) {
    @media print {
      width: $columnWidth !important;
      max-width: $columnWidth !important;
      min-width: $columnWidth !important;
    }
  }

  :global(.MuiDataGrid-cell) {
    @media print {
      width: $columnWidth !important;
      max-width: $columnWidth !important;
      min-width: $columnWidth !important;
    }
  }

  :global(.MuiDataGrid-cell img) {
    @media print {
      display: none !important;
    }
  }
}
