@import "../../assets/styles/_breakpoints.scss";
.container {
  display: flex;
  flex-direction: column;
  background: #fff;
  height: 100%;
  min-height: 100vh;
  min-width: 240px;
  max-width: 240px;
  gap: 20px;

  @include largeDesktop {
    min-width: 300px;
    max-width: 300px;
  }

  @media print {
    max-width: 0;
    width: 0;
    min-width: 0;
    overflow: hidden;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
