@import "../../../../assets/styles/breakpoints";

.switch {
  position: relative;
  display: flex;
  align-items: center;
  width: 200px;
  height: 100%;
  background: white;
  border: 1px solid var(--gray-border-secondary);
  border-radius: 20rem;
  padding: 0;
  margin: 0;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  cursor: pointer;
  font-size: 14px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
  }

  div {
    margin: 12px;
    z-index: 2;
    flex: 1 1 0;
    align-items: center;
    display: flex;
    color: var(--green);
  }

  .slider {
    position: absolute;
    border-radius: 20rem;
    width: 50%;
    height: calc(100% - 8px);
    margin: 4px;
    left: 4px;
    top: 4px;
    margin: 0;
    background: var(--green);
    z-index: 1;
  }

  input:checked {
    + .slider {
      left: calc(100% - 4px);
      transform: translate(-100%, 0);
    }
    ~ .map {
      color: white;
    }
  }

  input:not(:checked) {
    ~ .report {
      color: white;
      filter: none;
    }
  }

  input:disabled {
    + .slider {
      background: var(--progress-bar-bg);
    }
    ~ .report,
    ~ .map {
      color: var(--border-color);
    }
  }
}
