.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 20px;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.loginBtn {
  height: 50px;
}
