.menuItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 16px;
  height: 46px;
  text-decoration: none;
  user-select: none;
  gap: 15px;

  .container {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  &.active {
    p {
      font-weight: 600;
      letter-spacing: 0px;
      color: var(--green);
    }

    &.disabled {
      cursor: default;

      &:hover {
        color: black;
      }
    }
  }

  &.disabled {
    cursor: default;

    &:hover {
      color: #777;
    }
  }
}

.iconWrapper {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark {
  &:hover {
    color: white;
  }

  &.disabled {
    cursor: default;

    &:hover {
      color: #777;
    }
  }

  &.active {
    color: white;

    &.disabled {
      cursor: default;

      &:hover {
        color: white;
      }
    }
  }
}

.label {
  font: normal normal normal 14px/1 Sora;
  letter-spacing: 0px;
  color: var(--primary-text);
}

.newLabel {
  height: 15px;
  background-color: var(--green);
  border-radius: 3px;
  padding: 1px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font: normal normal normal 12px/1 Sora !important;
    color: var(--white) !important;
  }
}
