.button {
  padding: 5px 10px;
  border-radius: 15px;
  font-weight: bold;
  color: var(--terciary-text);

  box-sizing: border-box;
  cursor: pointer;
  max-height: 45px;

  background: transparent;
  font: normal normal 500 14px/1 Sora;

  border: 1px solid var(--terciary-text);
  transition: background 0.3s ease-out, color 0.3s ease-out;
  white-space: nowrap;
}

.active {
  color: var(--white);
  border-color: transparent;

  &.recyclingUnsuccessful {
    background: var(--red);
  }
  &.recyclingModerate {
    background: var(--orange);
  }
  &.recyclingSuccessful {
    background: var(--green);
  }
}
