.content {
  display: flex;
  flex-direction: column;
}

.item {
  padding: 20px 0;
  border-bottom: 2px solid #eff2f7;
}

.item:last-child {
  padding-bottom: 0px;
  border-bottom: none;
}

.item:first-child {
  padding-top: 0px;
}
