@import "../../../assets/styles/_breakpoints.scss";

.summaryCardsContainer {
  display: flex;
  background-color: #fff;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  overflow: hidden;
  padding: 29px 0 25px 0;
}

.summaryCard {
  display: flex;
  flex-direction: column;
  min-height: 100px;
  justify-content: space-between;
  width: 20%;
  border-left: 1px solid var(--gray-border);
  padding: 0 24px;
  &:first-of-type {
    border-left: none;
    padding: 0 24px 0 30px;
    @include largeDesktop {
      padding: 0 24px 0 40px;
    }
  }
  &:last-of-type {
    padding: 0 45px 0 24px;
  }
}

.title {
  font: normal normal normal 14px/22px Sora;
  letter-spacing: 0px;
  color: var(--secondary-text);
  @include largeDesktop {
    font: normal normal normal 16px/22px Sora;
  }
}

.value {
  font: normal normal 600 18px/25px Sora;
  letter-spacing: 0px;
  color: var(--primary-text);
  opacity: 0.8;
  @include largeDesktop {
    font: normal normal 600 20px/25px Sora;
  }
}
