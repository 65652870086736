@import "../../../assets/styles/breakpoints";

.banners {
  display: flex;
  justify-content: flex-start;
  gap: 25px;
  > * {
    width: 32%;
  }

  @media print {
    justify-content: space-around;
  }
}

.separationBanner {
  height: 150px;
  background: var(--gray-light);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 26px 0 26px 23px;
  gap: 30px;

  @media print {
    display: none;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  h4 {
    color: var(--primary-text);
    text-align: left;
    font: normal normal 600 14px/18px Sora;

    @include largeDesktop {
      font: normal normal 600 16px/24px Sora;
    }
  }
  p {
    color: var(--secondary-text);
    text-align: left;
    font: normal normal normal 13px/1 Sora;

    @include largeDesktop {
      font: normal normal normal 14px/1 Sora;
    }
  }
}
