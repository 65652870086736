@import "../../../assets/styles/_breakpoints.scss";

.sidepanel {
  background: var(--green-transparent);
  color: var(--white);
  border-radius: 0px var(--border-radius) var(--border-radius) 0px;
  opacity: 1;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 100px 50px 120px 50px;
  font-size: 14px;
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @include largeDesktop {
    padding: 123px 93px 150px 93px;
  }
}

.text {
  display: flex;
  flex-direction: column;
  gap: 42px;
}

.title {
  font: normal normal 600 24px/34px Sora;

  @include largeDesktop {
    width: 224px;
  }
}

.description {
  font: normal normal normal 14px/24px Sora;
}
