@import "../../assets/styles/_breakpoints.scss";

.card {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  overflow: hidden;
  gap: 24px;
  @include largeDesktop {
    gap: 54px;
  }
}

.contentSegment {
  padding: 0 30px 24px 30px;
  @include largeDesktop {
    padding: 0 40px 24px 40px;
  }
  flex: 1;
}

.headerSegment {
  max-height: 80px;
  height: 80px;
  padding: 0 30px;
  @include largeDesktop {
    padding: 0 40px;
  }
  display: flex;
  align-items: flex-end;
  h5 {
    font: normal normal 600 16px/1 Sora;
    letter-spacing: 0px;
    color: var(--primary-text);
    margin-bottom: 2px;
    @include largeDesktop {
      font: normal normal 600 20px/1 Sora;
    }
  }
  h6 {
    font: normal normal normal 14px/22px Sora;
    letter-spacing: 0px;
    color: var(--secondary-text);
    @include largeDesktop {
      font: normal normal normal 16px/22px Sora;
    }
  }
}

.headerSegmentContent {
  flex: 1;
  min-height: 48px;
  display: flex;
  align-items: center;
  > div {
    width: 100%;
  }
}

.fullWidth {
  width: 100%;
}

.noFlex {
  flex: unset;
}
