.title {
  margin-right: 6px;
  line-height: 1.25rem;
}

.popover {
  :global(.MuiPaper-root) {
    max-width: 240px;
    box-shadow: #00000020 0px 2px 12px;
    padding: 14px;
  }

  h1 {
    font-size: 14px;
  }
}

.textSize {
  :global(.MuiButton-label) {
    font-size: 12px;
  }
}

.filters {
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  gap: 6px;
}
