@import "../../assets/styles/breakpoints";

.progressBar {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 24px;
}

.back {
  background: #82828219;
  width: 70px;
  height: 6px;
  position: relative;
  border-radius: 3px;
}

.front {
  border-radius: 3px;
  height: 100%;
  display: inline-block;
  position: absolute;
  top: 0px;
  font-weight: bold;
}

.recyclingSuccessful {
  background: var(--green);
}
.recyclingModerate {
  background: var(--orange);
}
.recyclingUnsuccessful {
  background: var(--red);
}
.recyclingNone {
  background: var(--secondary-text);
}

.minWidth {
  min-width: 6px;
}
