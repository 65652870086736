:global(.MuiPickersCalendarHeader-root) {
  padding-left: 12px !important;
  :global(.MuiPickersCalendarHeader-labelContainer) {
    font-family: "Sora";
    position: absolute;
    color: var(--green);
    left: 50%;
    transform: translate(-50%);
  }
  :global(.MuiPickersArrowSwitcher-root) {
    width: 100%;

    :global(.MuiPickersArrowSwitcher-spacer) {
      flex-grow: 1;
    }
  }
}

:global(.MuiPickersDay-root.Mui-selected) {
  background-color: var(--green) !important;
  color: var(--white) !important;
}

:global(.MuiDialogActions-root) {
  justify-content: space-between !important;
  padding: 0 18px 18px 18px !important;
  button,
  button:hover {
    background: none;
    padding: 0 !important;
    text-transform: none;
  }
  button:nth-child(1) {
    color: var(--red);
  }
  button:nth-child(2) {
    color: var(--green);
  }
}
