.Pin {
  width: 44px;
  height: 44px;
  border-radius: 22px;
  color: var(--white);
  font-weight: bold;
  font-family: "Sora";
  position: relative;
  cursor: pointer;

  div {
    width: 100%;
    height: 100%;
    border-radius: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
  }

  .hoverOverlay {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: green;
    position: absolute;
    z-index: 1;
    transition: 0.2s;
    opacity: 0;
    border: 1px solid;
  }

  &:hover,
  &.hoverOverlayActive {
    .hoverOverlay {
      width: 180%;
      height: 180%;
      background: green;
      position: absolute;
      z-index: 1;
      opacity: 0.5;
      border-radius: 80px;
    }
  }

  .additional {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(25%, -25%);
    background: red;
    width: 22px;
    height: 22px;
  }

  .main {
    font-size: 16px;
  }
}
