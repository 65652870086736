@import "../../assets/styles/_breakpoints.scss";

.wrapperItems {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.wrapperText {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.description {
  font-size: var(--font-size-sm);
  color: var(--secondary-text);
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 32px;
  margin-right: 30px;

  @include largeDesktop {
    margin-right: 40px;
  }

  .saveBtn {
    min-width: 200px;
  }
}
