@import "../../assets/styles/_breakpoints.scss";

.container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 30px;
  @include largeDesktop {
    padding: 40px;
  }
}
