@import "../../../../../assets/styles/variables";

.container {
  color: black;
  width: 150px;
  padding: 8px;
  font-family: "Sora";

  div:not(:first-child) {
    border-top: 1px solid var(--gray-border);
  }

  div {
    padding: 8px;
    display: flex;
    flex-direction: row;
    font-size: 14px;

    span:first-child {
      width: 60%;
      color: var(--green);
    }

    span {
      color: var(--secondary-text);
    }
  }
}
