.topSegment {
  padding: 27px 25px 29px 17px;
  user-select: none;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--gray-table-border);
}

.wrapper {
  display: flex;
  flex-direction: column;
}
.logoWrapper {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  h2 {
    font: normal normal normal 20px/1 Sora;
    letter-spacing: 0px;
    color: var(--primary-text);
  }
}

.textContent {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.textWrapper {
  display: flex;
  gap: 7px;
  align-items: center;
  span {
    font: normal normal normal 14px/1 "Sora";
    letter-spacing: 0px;
    color: var(--terciary-text);
  }
}

.beta {
  font: normal normal normal 12px/1 Sora;
  letter-spacing: 0px;
  color: white;
  background: var(--secondary-text) 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 2px 3px;
}
