.controls {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 10px;
  width: 100%;
  margin-left: 64px;
  justify-content: space-between;

  > div {
    display: flex;
    gap: 10px;
  }
}
