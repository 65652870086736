.iconButton {
  transition: none;
  height: 50px;
  width: 50px;

  border-radius: 4px !important;
  background-color: var(--progress-bar-bg) !important;

  @media print {
    display: none !important;
  }
}

.menu {
  :global(.MuiPaper-root) {
    margin-top: 7px;
    box-shadow: 0px 8px 25px #afc4e266;
    li {
      height: 40px;
      padding: 6px 16px;
      color: var(--primary-text) !important;
      font: normal normal normal 14px/1 Sora !important;
    }
  }
}
