.NYI {
  width: 450px;
  .container {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 75px;
    background-color: #fff;
    border-radius: 12px;
    gap: 14px;
  }
  .title {
    font-weight: 700;
    font-size: 24px;
    margin-top: 15px;
  }
  .text {
    font-size: 14px;
  }
}

.centerWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
