@import "../../assets/styles/_breakpoints.scss";

.container {
  font-size: 14px;
  display: flex;
  padding: 0 53px 20px 67px;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  width: 100%;
  align-items: center;
  @include largeDesktop {
    padding: 0 53px 40px 67px;
  }

  .leftSection,
  .rightSection,
  .centerSection {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .leftSection {
    span {
      font-size: 30px;
      font-weight: normal;
    }
    .bold {
      font-weight: 600;
    }
  }

  .centerSection {
    font-size: 12px;
    justify-content: center;
    gap: 9px;
  }

  .rightSection {
    justify-content: flex-end;
    gap: 32px;
  }

  .rightSectionContent {
    display: flex;
    align-items: center;
    gap: 20px;
    .transparent {
      opacity: 0.7;
    }
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
  > label {
    &:first-of-type {
      opacity: 0.5;
    }

    &:nth-of-type(2) {
      font-weight: 400;
    }
  }
}
