.rowHeaderCell {
  display: flex;
}

.title {
  font: normal normal 500 14px/18px Sora;
  letter-spacing: 0px;
  color: var(--primary-text);
}

.wasteTypeCode {
  font: normal normal normal 12px/1 Sora;
  letter-spacing: 0px;
  color: var(--terciary-text);
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
