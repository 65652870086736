.menuItemWrapper a {
  text-decoration: none;
}

.menuItemWrapper {
  margin: 0px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
  border-radius: 8px;
  &:hover {
    background-color: var(--gray-light);
  }
}

.active {
  background: var(--green-light) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  &:hover {
    background-color: var(--green-light);
  }
}
