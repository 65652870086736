.locationCell {
  button:hover,
  button:hover + span {
    background: none;
    color: var(--terciary-text);
  }
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 6px;
}
.weightCell {
  display: flex;
  align-items: center;
  gap: 8px;
}
