@import "../../../assets/styles/_breakpoints.scss";

.container {
  width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  border-radius: var(--border-radius);
  padding: 50px 55px;
  box-sizing: border-box;
  gap: 40px;

  @include largeDesktop {
    padding: 100px 93px 95px 100px;
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.top {
  display: flex;
  flex-direction: column;
  gap: 48px;
  p {
    font: normal normal 500 20px/34px Sora;
  }
}

.dark {
  background-color: #191919;

  .container {
    background-color: #212121;
  }
}

.footer {
  font: normal normal normal 14px/25px Sora;
  max-width: 300px;
  a {
    color: var(--terciary-text);
    text-decoration: underline;
    font: normal normal normal 14px/25px Sora;
  }
}
