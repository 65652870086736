.pickerDay {
  button {
    font-family: "Sora";
    height: 28px;
    border-radius: 20px;
    border: none !important;

    &:global(.Mui-selected),
    &:hover {
      background-color: var(--green);
      color: white;
      &:hover {
        background-color: var(--green);
      }
    }

    &:global(.MuiPickersDay-dayOutsideMonth) {
      color: var(--border-color);
    }
  }

  &:global(.MuiDateRangePickerDay-rangeIntervalDayHighlight) {
    :global(.MuiDateRangePickerDay-rangeIntervalPreview) {
      background-color: var(--green-light);
      border-radius: 0px !important;
      color: black;
    }
  }
  &:global(.MuiDateRangePickerDay-rangeIntervalDayHighlightStart) {
    :global(.MuiDateRangePickerDay-rangeIntervalPreview) {
      background-color: var(--green-light);
      border-radius: 20px 0 0 20px !important;
    }
    :global(.MuiDateRangePickerDay-rangeIntervalDayPreview) {
      border-radius: 0 0px 0px 0 !important;
    }
  }
  &:global(.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd) {
    :global(.MuiDateRangePickerDay-rangeIntervalPreview) {
      border-radius: 0 20px 20px 0 !important;
    }
    :global(.MuiDateRangePickerDay-rangeIntervalDayPreview) {
      border-radius: 0 0px 0px 0 !important;
    }
  }
  :global(.MuiDateRangePickerDay-rangeIntervalDayPreviewStart) {
    &:hover {
      border-radius: 20px 0px 0px 20px !important;
    }
  }
  :global(.MuiDateRangePickerDay-rangeIntervalDayPreviewEnd) {
    &:hover {
      border-radius: 0px 20px 20px 0px !important;
    }
  }

  &:global(.MuiDateRangePickerDay-rangeIntervalDayHighlight) {
    :global(.MuiDateRangePickerDay-rangeIntervalDayPreviewStart) {
      border-radius: 0 0px 0px 0 !important;
      button {
        background: none;
        color: black;
        font-weight: normal !important;
      }
    }
  }
  &:global(.MuiDateRangePickerDay-rangeIntervalDayHighlight) {
    :global(.MuiDateRangePickerDay-rangeIntervalDayPreviewEnd) {
      border-radius: 0 0px 0px 0 !important;
      button {
        background: none;
        color: black;
        font-weight: normal !important;
      }
    }
  }

  :global(.MuiDateRangePickerDay-rangeIntervalDayPreview:not(.MuiDateRangePickerDay-rangeIntervalDayHighlightStart.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd)) {
    background: var(--green-light) !important;
    border-radius: 0 !important;
    border-color: transparent !important;
  }
}

.pinContainer {
  bottom: 0;
  display: flex;
  gap: 2px;
  justify-content: center;
  margin: 2px 0;
}
