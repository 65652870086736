.loader {
  width: 312px;
  padding-left: calc((312px - 280px) / 2);
  padding-right: calc((312px - 280px) / 2);
  height: 264px;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  align-content: flex-start;
}
