.skeleton {
  background-color: rgb(239, 242, 247);
  border-radius: 4px;
  width: 100%;
  height: 32px;
  cursor: progress;
  overflow: hidden;
  position: relative;
}

.skeleton:after {
  background-image: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.5) 50%, transparent 100%);
  width: 120px;
  height: 100%;
  content: "";
  animation: shimmer 1.5s linear infinite;
  position: absolute;
  right: 0;
}

@keyframes shimmer {
  0% {
    left: -120px;
  }
  100% {
    left: 100%;
  }
}
