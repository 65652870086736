.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  align-self: center;
}

.lineChartContainer {
  width: 100%;
  position: relative;
}

.lineChart {
  flex: 1;
  width: 400px;
}

.labels {
  display: flex;
  width: 90%;
  justify-content: center;
  margin-top: 0.5rem;
}

.label {
  border-radius: 15px;
  margin: 0 5px;
  letter-spacing: -0.28px;
  color: #ffffff;
}

.tooltip {
  position: absolute;
  transform: translate(-50%, calc(-100% - 15px));
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 9px 15px #00000017;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-radius: 12px;
  > * {
    white-space: nowrap;
  }
  h5 {
    color: var(--green);
    font: normal normal bold 13px/1 Sora;
  }
  p {
    color: var(--terciary-text);
    font: normal normal normal 13px/1 Sora;
  }

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: #ffffff transparent transparent transparent;
    top: 100%;
    left: calc(50% - 10px);
  }
}
