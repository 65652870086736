.imageContainer {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}

.overlayText {
  text-align: center;
  width: 260px;
}

.progressBar {
  width: 260px;
  height: 6px;
  background: var(--progress-bar-bg);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.progressBarValue {
  height: 100%;
  background-color: var(--green);
  animation: indeterminateAnimation 2s infinite ease-in-out alternate;
  border-radius: 6px;
}

@keyframes indeterminateAnimation {
  0% {
    width: 0%;
  }
  50% {
    width: 100%;
    transform: translateX(0%);
  }
  100% {
    width: 100%;
    transform: translateX(100%);
  }
}
