.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.filtersContainer {
  display: flex;
  align-items: center;
}

.title {
  margin-right: 6px;
  line-height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popover {
  :global(.MuiPaper-root) {
    max-width: 400px;
    box-shadow: #00000020 0px 2px 12px;
    padding: 14px;
  }

  h1 {
    font-size: 14px;
  }
}

.textSize {
  :global(.MuiButton-label) {
    font-size: 12px;
  }
}

.filters {
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  gap: 6px;
  font-size: 14px;
}

.wrapperFilterIcon {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  height: 16px;
  width: 20px;
}
