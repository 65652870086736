.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 540px;
  padding: 58px;
  background-color: white;
  box-shadow: 0px 15px 60px #0000000d;
  border-radius: 12px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal {
  position: fixed;
  z-index: 100;
  :global(.MuiModal-root) {
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
  }
  .modalContainer {
    animation: 0.3s ease fade-in;
  }

  :global(.MuiBackdrop-root) {
    background-color: rgba(0, 0, 0, 0.2);
    transition: none !important;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modalHeader {
  color: var(--primary-text);
  text-align: left;
  font: normal normal 600 24px/34px Sora;
  letter-spacing: 0px;
}

.modalText {
  color: var(--primary-text);
  text-align: left;
  font: normal normal normal 14px/25px Sora;
  letter-spacing: 0px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.image {
  padding: 0 20px;
}

.center {
  .modalHeader,
  .modalText {
    text-align: center;
  }
  .modalText {
    padding: 0 55px;
  }
}
