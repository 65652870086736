.headerContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.filters {
    display: flex;
    align-items: center;
    gap: 10px;
}
.inputs {
    display: flex;
    align-items: center;
    gap: 10px;
}