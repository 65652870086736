.paymentStatusBtn {
  display: flex;
  align-items: center;
  border: 1px solid var(--gray-border-secondary);
  border-radius: 15px;
  height: 28px;
  padding: 5px 10px;
  cursor: pointer;
  p {
    color: var(--secondary-text);
    text-align: left;
    font: normal normal normal 14px/1 Sora;
    letter-spacing: 0px;
  }
  @media print {
    &:not(.active) {
      display: none;
    }
    &:is(.active.paid) p {
      color: var(--green);
    }
    &:is(.active.unpaid) p {
      color: var(--red);
    }
  }
}

.active {
  border: none;
  p {
    color: white;
  }
  &.paid {
    background-color: var(--green);
  }
  &.unpaid {
    background-color: var(--red);
  }
  &.moved {
    background-color: var(--terciary-text);
  }
}
